<template>
  <b-container fluid v-if="isLoaded">
    <div v-if="!hasError">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>{{ productDetailsTitleText }}</h2>
        <b-button-toolbar class="pb-3">
          <super-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'inventory' }"><i class="fas fa-caret-left"/>
            <span class="d-none d-lg-inline ml-1">Înapoi</span>
          </super-button>
        </b-button-toolbar>
        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-lg-none">Înapoi</b-tooltip>
      </div>
      <div v-if="shouldDisplay" class="container-fluid">
        <b-container fluid>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod produs:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codProdus }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Categorie produs:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.categorieProdus }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Denumire:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.denumire }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Unitatea de măsură:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.unitateMasura }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Descrierea:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.descriere }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod produs părinte:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codProdusParinte }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Număr cont:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.numarCont }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod raportare intrastat:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codRaportareIntrastat }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod valută preț referință:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codValutaPretReferinta }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Preț referință:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.pretReferinta }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Stoc critic:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.stocCritic }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Stoc maxim:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.stocMaxim }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Este activ:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.esteActiv }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Este serviciu:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.esteServiciu }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod timbru verde:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codTimbruVerde }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Preț maximal vânzare:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codTimbruAlb }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Este produs atipic:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.esteProdusAtipic }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod bare:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codBare }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Procent TVA cerut:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.procentTvaCerut }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod taxare inversă raportare:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codTaxareInversaRaportare }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod CAEN activitate raportare:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.CodCaenActivitateRaportare }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod regim special raportare:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.codRegimSpecialRaportare }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Este exceptat utilizare AMEF:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.esteExceptatUtilizareAmef }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cantitate cutie:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.cantitateCutie }}</b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProductDetails',
  components: {
  },
  data() {
    return {
      productCode: '',
      shouldDisplay: false,
      hasError: false,
      isLoaded: false,
      errorMessage: '',
      details: {
        codProdus: '',
        categorieProdus: '',
        denumire: '',
        unitateMasura: '',
        descriere: '',
        codProdusParinte: '',
        numarCont: '',
        codRaportareIntrastat: '',
        codValutaPretReferinta: '',
        pretReferinta: 0,
        stocCritic: 0,
        stocMaxim: 0,
        esteActiv: false,
        esteServiciu: false,
        codTimbruVerde: '',
        pretMaximalVanzare: 0,
        esteProdusAtipic: false,
        codBare: '',
        procentTvaCerut: 0,
        codTaxareInversaRaportare: '',
        CodCaenActivitateRaportare: '',
        codRegimSpecialRaportare: '',
        esteExceptatUtilizareAmef: false,
        cantitateCutie: 0,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.productCode = to.params.productCode;
      vm.getProductDetails();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.productCode = to.params.productCode;
    this.getProductDetails();
    next();
  },
  created() {
    this.productCode = this.$route.params.productCode;
    this.getProductDetails();
  },
  computed: {
    ...mapState(['xhrRequestRunning', 'lastRoute']),
    productDetailsTitleText() {
      return this.shouldDisplay
        ? `Detalii produs${this.details ? ` - ${this.details.denumire}` : ''}`
        : 'Nu există produs';
    },
  },
  methods: {
    ...mapActions([
      'performProductDetailsLookup',
    ]),

    async getProductDetails() {
      this.isLoaded = false;
      try {
        const result = await this.performProductDetailsLookup(this.productCode);
        this.details = result || this.details;
        this.shouldDisplay = !!result;
        this.hasError = false;
      } catch (error) {
        this.hasError = true;
        this.errorMessage = error.response.data;
      } finally {
        this.isLoaded = true;
      }
    },
    reloadPage() {
      this.$router.go(this.$router.currentRoute);
    },
  },
};

</script>
